<template>
    <div class="main-container">
        <ErrorMessage v-if="!categoryIsValid" ref="errorMessage" size="large">
            Incomplete fields
        </ErrorMessage>

        <div v-if="categoryDetails" class="page-layout-container">
            <div class="category-card__details">
                <Input
                    id="category-name"
                    name="category-name"
                    type="text"
                    label="Category Name"
                    :max="200"
                    show-remaining-char
                    :value="categoryDetails.category.name"
                    :errors="categoryDetails.errors.name"
                    @input="(value) => onInput('name', value)"
                />
                <Input
                    id="category-description"
                    name="category-description"
                    type="textarea"
                    label="Description"
                    :max="200"
                    show-remaining-char
                    :value="categoryDetails.category.description"
                    :errors="categoryDetails.errors.description"
                    @input="(value) => onInput('description', value)"
                />
                <Input
                    id="category-slug"
                    name="category-slug"
                    type="text"
                    label="Slug"
                    :value="categoryDetails.category.slug"
                    :errors="categoryDetails.errors.slug"
                    :disabled="!slugIsEditable"
                    @input="(value) => onInput('slug', value)"
                />
                <ColorPicker
                    id="category-color"
                    name="category-color"
                    class="picker"
                    :errors="categoryDetails.errors.color"
                    :value="categoryDetails.category.color"
                    :already-in-use="alreadyInUse"
                    :color-options="colorOptions.length ? colorOptions : undefined"
                    @input="(value) => onInput('color', value)"
                />
            </div>
            <div class="category-card__image">
                <ImageUploaderButton
                    show-preview
                    input-style="green"
                    class="channel-image m-b-1"
                    :aspect-ratio="16 / 9"
                    :button-label="'Select image'"
                    thumbnail-location="top"
                    :thumbnail-size="178"
                    show-action-within
                    :value="categoryDetails.category.image"
                    :errors="categoryDetails.errors.image"
                    @input="(value) => onInput('image', value)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';
import ErrorMessage from '@/components/ui/ErrorMessage';
import ColorPicker from '@/components/ui/ColorPicker';
import Input from '@/components/forms/Input';

export default {
    name: 'CategoryCard',
    components: {
        Input,
        ImageUploaderButton,
        ColorPicker,
        ErrorMessage,
    },
    props: {
        categoryDetails: {
            type: Object,
            required: true,
        },
        categoryIsValid: {
            type: Boolean,
            required: true,
        },
        colorOptions: {
            type: Array,
            default: () => [],
        },
        alreadyInUse: {
            type: Array,
            default: () => [],
        },
        slugIsEditable: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onInput(key, value) {
            if (key === 'name' && this.slugIsEditable) {
                this.categoryDetails.category['slug'] = value;
            }
            this.categoryDetails.category[key] = value;
            this.$emit('change', this.categoryDetails.category);
        },
        animateErrorMessage() {
            this.$refs.errorMessage.animate();
        },
    },
};
</script>

<style lang="scss" scoped>
.main-container {
    width: 100%;
}
.page-layout-container {
    .category-card {
        &__details {
            flex: 0 0 70%;
        }
        &__image {
            flex: 0 0 30%;
        }
    }
}
</style>
