<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner :show="pageIsLoading" />

            <template v-if="!pageIsLoading">
                <PageHeading :breadcrumbs="breadcrumbs" title="Edit category" />

                <div class="category-edit-container">
                    <Panel title="Category Settings" class="category-panel m-b-4">
                        <CategoryCard
                            v-if="categoryDetails.category"
                            ref="categoryCard"
                            :category-details="categoryDetails"
                            :category-is-valid="categoryIsValid"
                            :color-options="colorOptions"
                            :already-in-use="colorsAlreadyInUse"
                        />
                    </Panel>

                    <Panel title="Channels" class="channel-panel m-b-4">
                        <div class="channel-panel-header">List of channels in this category</div>

                        <div class="channel-actions">
                            <Checkbox
                                id="all"
                                v-model="mainCheckBox"
                                :custom-icon="customIcon"
                                label
                                :value="false"
                                @change="mainCheckboxClicked($event)"
                            />
                            <template v-if="selectedChannels.length">
                                <div class="action-container">
                                    <button class="action-change" @click="changeCategory()">
                                        Change category
                                    </button>
                                    |
                                    <button class="action-remove" @click="removefromCategory()">
                                        Remove from list
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="action-container">
                                    <span class="action-select">SELECT CHANNEL(s)</span>
                                </div>
                            </template>
                        </div>
                        <div id="style-1" class="channel-list-scroll">
                            <div
                                v-for="(channel, index) in channelList"
                                :id="`${channel.name}-${index}`"
                                :key="index"
                                class="channel-container"
                            >
                                <Checkbox
                                    :id="`checkbox-${channel.name}-${index}`"
                                    v-model="selectedChannels"
                                    label
                                    :input-value="`${channel.name}`"
                                />
                                <label
                                    :for="`checkbox-${channel.name}-${index}`"
                                    class="channel-content"
                                >
                                    <Avatar class="channel-avatar" :image-url="channel.image" />
                                    <span class="channel-name">{{ channel.name }}</span>
                                </label>
                            </div>
                        </div>
                    </Panel>
                </div>
                <button class="delete-category" @click="deleteSelectedCategory()">
                    Delete Category
                </button>
                <Stepper
                    show-back-button
                    show-next-button
                    next-button-text="Save Category"
                    :is-loading="false"
                    @backButtonOnClick="backButtonClick($event)"
                    @nextButtonOnClick="confirmButtonClick($event)"
                />
            </template>
            <Dialog
                show-confirm-button
                confirm-button-text="Yes, Remove"
                close-button-text="Cancel"
                :is-dialog-visible="removeCategoryConfirmation"
                @onClose="removeCategoryConfirmation = false"
                @onClickOutside="removeCategoryConfirmation = false"
                @closeOnEscapeEvent="removeCategoryConfirmation = false"
                @confirmButtonOnClick="removeCategoryConfirmed($event)"
            >
                <template #header>{{ removeCategoryHeader }}</template>
                <template #body>
                    <div v-if="!allChannelsAreSelected()" class="dialog-container-channel">
                        <div
                            v-for="(channel, index) in selectedForModification"
                            :key="`dialog-remove-${channel.name}-${index}`"
                            class="channel-details-container"
                        >
                            <Avatar class="channel-avatar" :image-url="channel.image" />
                            <span class="channel-name">{{ channel.name }}</span>
                        </div>
                    </div>
                </template>
            </Dialog>
            <Dialog
                show-confirm-button
                confirm-button-text="Move Channels"
                close-button-text="Cancel"
                :is-dialog-visible="changeCategoryConfirmation"
                @onClose="changeCategoryConfirmation = false"
                @onClickOutside="changeCategoryConfirmation = false"
                @closeOnEscapeEvent="changeCategoryConfirmation = false"
                @confirmButtonOnClick="changeCategoryConfirmed($event)"
            >
                <template #header>
                    Select a category you would like to move the following channels from "{{
                        categoryName
                    }}
                    to:"
                </template>
                <template #body>
                    <div class="dialog-container-channel">
                        <div
                            v-for="(channel, index) in selectedForModification"
                            :key="`dialog-remove-${channel.name}-${index}`"
                            class="channel-details-container"
                        >
                            <Avatar class="channel-avatar" :image-url="channel.image" />
                            <span class="channel-name">{{ channel.name }}</span>
                        </div>
                    </div>
                    <Select
                        v-model="moveChannenlsToCategory"
                        :options="categoriesDropdown"
                        placeholder="Select a category"
                        has-margin-bottom
                        label="Move to category"
                        slot-enabled
                    >
                        <template v-slot:select="slotProps">
                            <span
                                :style="{ background: `${slotProps.option.color}` }"
                                class="select-category-color"
                            ></span>
                            <span class="select-category-value">{{ slotProps.option.value }}</span>
                        </template>
                    </Select>
                </template>
            </Dialog>
            <Dialog
                show-confirm-button
                confirm-button-text="Delete Category"
                close-button-text="Cancel"
                :is-dialog-visible="deleteCategoryConfirmation"
                @onClose="deleteCategoryConfirmation = false"
                @onClickOutside="deleteCategoryConfirmation = false"
                @closeOnEscapeEvent="deleteCategoryConfirmation = false"
                @confirmButtonOnClick="deleteCategoryConfirmed($event)"
            >
                <template #header>
                    Do you really want to delete "{{ categoryName }} category". All channels in this
                    category would be uncategorised
                </template>
            </Dialog>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import PageHeading from '@/components/ui/PageHeading';
import CategoryCard from '@/components/CategoryCard';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import Checkbox from '@/components/forms/Checkbox';
import Stepper from '@/components/ui/Stepper';
import Panel from '@/components/ui/Panel';
import Avatar from '@/components/ui/Avatar';
import Dialog from '@/components/ui/Dialog';
import Select from '@/components/forms/Select';
import { router } from '../router';

export default {
    name: 'CategoryEdit',
    components: {
        FullPageLoadingSpinner,
        PageHeading,
        Panel,
        CategoryCard,
        Checkbox,
        Avatar,
        Dialog,
        Select,
        Stepper,
    },
    data() {
        return {
            categoryName: undefined,
            selectedChannels: [],
            selectedForModification: [],
            breadcrumbs: [],
            channels: [],
            customIcon: 'minus',
            mainCheckBox: false,
            colorsAlreadyInUse: [],
            colorOptions: undefined,
            removeCategoryConfirmation: false,
            changeCategoryConfirmation: false,
            removeCategoryHeader: '',
            moveChannenlsToCategory: '',
            deleteCategoryConfirmation: false,
            categoriesDropdown: [],
        };
    },
    computed: {
        ...mapGetters('category', {
            categoryDetails: 'categoryDetails',
            categoryModelHasChanges: 'categoryModelHasChanges',
            categoryIsValid: 'categoryIsValid',
            getColorsInUse: 'getColorsInUse',
            categories: 'categories',
            channelList: 'channelList',
        }),
        pageIsLoading() {
            return this.$wait.is(LoadingFlag.CategoryGetOne);
        },
    },
    watch: {
        selectedChannels() {
            if (this.selectedChannels.length) {
                this.mainCheckBox = true;
                if (this.allChannelsAreSelected()) {
                    this.customIcon = 'check';
                }
            } else {
                this.mainCheckBox = false;
            }
        },
    },
    async mounted() {
        if (!this.categories.length) {
            await this.getCategories();
        }
        const id = this.$route.params.categoryId;
        await this.getCategory(id);
        this.setBreadcrumbs();
        this.setColors();
        this.setCategoryDetails();
    },
    methods: {
        ...mapActions('category', [
            'getCategory',
            'resetCategoryDetailsState',
            'getCategories',
            'validateCategory',
            'saveCategory',
            'deleteCategory',
            'updateChannelsInCategory',
        ]),
        async setBreadcrumbs() {
            const breadcrumbs = [];
            const categoryLink = { name: 'category' };
            breadcrumbs.push(new Breadcrumb('categories', categoryLink));
            if (this.categoryDetails && this.categoryDetails.category.id) {
                breadcrumbs.push(new Breadcrumb(this.categoryDetails.category.name));
            }
            this.breadcrumbs = breadcrumbs;
        },
        setCategoryDetails() {
            this.categoryName = this.categoryDetails.category && this.categoryDetails.category.name;
            this.channels =
                this.categoryDetails.category && this.categoryDetails.category.channelList;
            this.categoriesDropdown = [];
            this.categories.filter((category) => {
                if (category.name !== this.categoryName && category.id !== 1) {
                    this.categoriesDropdown.push({
                        id: category.id,
                        value: category.name,
                        color: category.color,
                    });
                }
            });
        },

        addAllChannelsToSelection() {
            this.categoryDetails.category.channelList.map((c) => {
                this.selectedChannels.push(c.name);
            });
            this.customIcon = 'check';
        },
        removeAllChannelsFromSelection() {
            this.selectedChannels = [];
            this.customIcon = 'minus';
        },
        mainCheckboxClicked(event) {
            if (this.selectedChannels.length === 0) {
                this.addAllChannelsToSelection();
            } else {
                this.removeAllChannelsFromSelection();
            }
        },
        setColors() {
            const inUse = this.getColorsInUse || [];
            this.colorsAlreadyInUse = inUse.filter(
                (item) => item != this.categoryDetails.category.color,
            );
        },
        prepareForCategoryModification() {
            this.selectedChannels.map((e) => {
                const channelDetail = this.channels.find((channel) => channel.name === e);
                if (channelDetail) {
                    this.selectedForModification.push(channelDetail);
                }
            });
        },
        changeCategory() {
            this.selectedForModification = [];
            this.prepareForCategoryModification();
            this.changeCategoryConfirmation = true;
        },
        allChannelsAreSelected() {
            return this.channels && this.channels.length === this.selectedChannels.length;
        },
        removefromCategory() {
            this.selectedForModification = [];
            this.removeCategoryHeader = `Are you sure you would like to remove the following channels from the "${this.categoryName}" category?`;
            if (this.allChannelsAreSelected()) {
                this.removeCategoryHeader = `Are you sure you would like to remove all the channels from "${this.categoryName}" category?`;
            }
            this.prepareForCategoryModification();
            this.removeCategoryConfirmation = true;
        },
        async deleteSelectedCategory() {
            this.deleteCategoryConfirmation = true;
        },
        async deleteCategoryConfirmed(event) {
            const categoryId = this.categoryDetails.category.id;
            await this.deleteCategory(categoryId);
            this.deleteCategoryConfirmation = false;
            this.$nextTick(() => {
                router.push('/category');
            });
        },
        async removeCategoryConfirmed(event) {
            const selectedChannelIds = this.selectedForModification.map((o) => o.id);
            await this.updateChannelsInCategory({
                channelList: selectedChannelIds,
                originalCategory: this.categoryDetails.category.id,
            });
            this.removeCategoryConfirmation = false;
            this.mainCheckBox = false;
            this.moveChannenlsToCategory = '';
            this.selectedChannels = [];
            this.selectedForModification = [];
        },
        async changeCategoryConfirmed(event) {
            if (this.moveChannenlsToCategory) {
                const selectedChannelIds = this.selectedForModification.map((o) => o.id);
                await this.updateChannelsInCategory({
                    categoryId: this.moveChannenlsToCategory,
                    channelList: selectedChannelIds,
                    originalCategory: this.categoryDetails.category.id,
                });
                this.changeCategoryConfirmation = false;
                this.mainCheckBox = false;
                this.moveChannenlsToCategory = '';
                this.selectedChannels = [];
                this.selectedForModification = [];
            }
        },
        async confirmButtonClick() {
            await this.validateCategory();
            if (!this.categoryIsValid) {
                this.$refs.categoryCard.animateErrorMessage();
                return;
            }
            if (!this.categoryModelHasChanges) {
                return;
            }
            await this.saveCategory();

            this.$nextTick(() => {
                router.push('/category');
            });
        },
        backButtonClick(event) {
            router.push('/category');
        },
    },
    async beforeRouteLeave(to, from, next) {
        if (!this.$route.meta.group || to.meta.group !== this.$route.meta.group) {
            this.resetCategoryDetailsState();
        }

        next();
    },
};
</script>

<style lang="scss">
.dialog {
    overflow: visible;
}
</style>

<style lang="scss" scoped>
.category-edit-container {
    display: flex;
    .category-panel {
        flex: 0 0 70%;
        margin-right: 5%;
    }
    .channel {
        &-panel {
            flex: 0 0 30%;
            &-header {
                font-size: 14px;
            }
        }
        &-container {
            width: 100%;
            display: flex;
            .input-wrapper {
                padding-top: 4px;
                margin-right: 8px;
            }
        }
        &-content {
            padding-top: 4px;
            margin-bottom: 16px;
            cursor: pointer;
        }
        &-avatar {
            margin-right: 10px;
        }
        &-name {
            vertical-align: middle;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            width: 12em;
        }
        &-actions {
            margin: 14px 0;
            font-size: 10px;
            width: 100%;
            display: flex;
            text-align: right;
            .input-wrapper {
                padding-top: 4px;
                margin-right: 8px;
            }
        }
    }
    .action {
        &-container {
            width: 100%;
            margin-top: 4px;
        }
        &-remove,
        &-change {
            text-transform: uppercase;
            vertical-align: middle;
            color: $green-style-input;
            cursor: pointer;
        }
        &-select {
            color: $alternate-text-color;
        }
    }
    .channel-list-scroll {
        max-height: 300px;
        overflow-y: auto;
    }
    .channel-list-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(250, 251, 252, 0.3);
        background-color: #fafbfc;
        border-radius: 2px;
    }

    .channel-list-scroll::-webkit-scrollbar {
        width: 5px;
        background-color: #fafbfc;
    }

    .channel-list-scroll::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: $green-style-input;
    }
}
.delete-category {
    color: $alternate-text-color;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: 10px;
    letter-spacing: 1.6px;
    cursor: pointer;
}
.dialog-container-channel {
    display: flex;
    flex-wrap: wrap;
    .channel-name {
        margin: 6px 0 0 10px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
    .channel-details-container {
        width: 40%;
        margin: 10px;
        display: inline-flex;
    }
}
.select-category {
    &-color {
        $size: 10px;
        height: $size;
        width: $size;
        border-radius: $size;
        display: inline-block;
        margin-right: $size;
        vertical-align: middle;
    }
    &-value {
        font-size: $font-size-xs;
    }
}
</style>
